import React from "react";
import styled from "styled-components";
import TextPageTemplate, {
  TextBox,
  Paragraph,
  TinyTitle,
} from "../templates/textPageTemplate";

const About = () => {
  return (
    <TextPageTemplate
      title={"About"}
      description={
        "Theodor Hillmann, an experienced interaction and interface designer, specializes in creating engaging and user-centered web experiences for forward-thinking companies. "
      }
    >
      <TextBox
        gridColumn={"2/4"}
        gridColumnDesktop={"2/3"}
        gridColumnMedium={"2/5"}
        gridRow={3}
      >
        <TinyTitle>About</TinyTitle>
        <Paragraph>
          Theodor Hillmann, an experienced interaction and interface designer,
          specializes in creating engaging and user-centered web experiences for
          forward-thinking companies. With a keen understanding of the
          importance of well-crafted design in building strong brands, Hillmann
          has a proven track record of delivering exceptional results. Whether
          working on revamping a website, designing a new app, or creating a
          digital campaign, Hillmann's passion for beautiful and intuitive
          interfaces helps businesses stand out in today's competitive digital
          landscape.
        </Paragraph>
        <TinyTitle>Typography</TinyTitle>
        <Paragraph>
          <a
            href="https://abcdinamo.com/typefaces/monument-grotesk"
            target="_blank"
          >
            Monument Grotesk
          </a>
          <br />
          by ABC Dinamo
        </Paragraph>
      </TextBox>
    </TextPageTemplate>
  );
};

export default About;
